import React, { useState } from "react"
import { css, Theme } from "@emotion/react"
import {
	CustomerPart,
	CustomerPartQueryParams,
	initialCustomerPartQueryParams,
	useCustomerPartsEcomm,
} from "@ncs/ncs-api"
import { arrayWrap } from "@ncs/ts-utils"
import {
	Box,
	Button,
	FeatureFlag,
	Flag,
	GridContainer,
	GridItem,
	LoadingSpinner,
	NyrisCameraCircleIcon,
	Paragraph,
	ResultCountSummaryEcomm,
	useUrlState,
	handleNyrisSearch,
} from "@ncs/web-legos"
import { AddedToCartModalEcomm, PageContentWrapper } from "~/shared-components"
import {
	PaginationEcomm,
	PartSearchSidebarEcomm,
	PartCardEcomm,
	PartSortEcomm,
	ActiveFilters,
} from "./components"
import { SearchFilterModalEcomm } from "./components/Filters/SearchFilterModalEcomm"

export type PartSearchResultsUrlStateEcomm = Omit<
	CustomerPartQueryParams,
	"categories" | "manufacturers" | "systems" | "chemicals"
> & {
	// We're changing these types so that the backend doesn't need to JSON stringify these.
	manufacturers: string | number | (string | number)[]
	categories: string | number | (string | number)[]
	systems: string | number | (string | number)[]
	chemicals: string | number | (string | number)[]
}

export const PartSearchResultsEcomm: React.FC = () => {
	const [addedPart, setAddedPart] = useState<CustomerPart | null>(null)
	const [params] = useUrlState<PartSearchResultsUrlStateEcomm>(initialCustomerPartQueryParams)

	const {
		data: parts,
		currentPage,
		resultPageCount,
		isLoading,
		resultCountEstimate,
		fetchPage,
	} = useCustomerPartsEcomm({
		params: {
			...params,
			categories: arrayWrap(params.categories).map((c) => String(c)),
			manufacturers: arrayWrap(params.manufacturers).map((m) => String(m)),
			systems: arrayWrap(params.systems).map((s) => String(s)),
			chemicals: arrayWrap(params.chemicals).map((s) => String(s)),
			show_children: false,
		},
		pageSize: 24,
		removeChildren: true,
	})

	const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)
	return (
		<PageContentWrapper
			title="Product Search"
			breadcrumbs={[{ name: "Shop", to: "/shop" }, { name: "Search" }]}
		>
			<div css={contentContainer}>
				<div css={sideBarFilter}>
					<PartSearchSidebarEcomm />
				</div>

				<div>
					<div css={searchResultsStatusHeaderStyle}>
						<div css={resultStyle}>
							<ResultCountSummaryEcomm
								currentTotal={parts.length}
								totalResults={resultCountEstimate}
								searchItem={params.search}
							/>
						</div>

						<div css={filterHeader}>
							<div css={filterButton}>
								<Button onClick={() => setShowSearchFilterModal(true)}>
									Filters
								</Button>
							</div>
							<PartSortEcomm />
						</div>
					</div>

					<ActiveFilters />

					<FeatureFlag flag={Flag.NYRIS}>
						<Box mb={2} css={nyrisIconBoxStyle}>
							<Button onClick={handleNyrisSearch}>
								<NyrisCameraCircleIcon />
							</Button>
							<Box css={snapSearchBoxStyle}>
								<p css={snapSearchStyle}>Snap & Search</p>
								<p css={nyrisDescriptionStyle}>
									Find what you need in a snap! Simply take or upload a photo,
									and our AI will match it to the perfect product.
								</p>
							</Box>
						</Box>
					</FeatureFlag>

					{isLoading && <LoadingSpinner text="Searching..." py={10} />}

					<GridContainer>
						{parts.map((part) => (
							<GridItem
								key={part.id}
								xs={12}
								sm={6}
								md={4}
								lg={3}
								mb={4}
								css={gridPartCard}
							>
								<PartCardEcomm
									part={part}
									onAddToCart={() => setAddedPart(part)}
									css={partCardStyle}
								/>
							</GridItem>
						))}
					</GridContainer>

					{parts.length > 0 && (
						<PaginationEcomm
							numberOfPages={resultPageCount ?? 0}
							fetchPage={fetchPage}
							currentPage={currentPage ?? 0}
							isLoading={isLoading}
						/>
					)}

					{!isLoading && parts.length === 0 && (
						<Box my={8} textAlign="center">
							<Paragraph mt={2} color="secondary">
								Sorry, no results were found. Try a different search?
							</Paragraph>
						</Box>
					)}
				</div>
			</div>

			<AddedToCartModalEcomm
				isOpen={!!addedPart}
				partId={addedPart?.id}
				onClose={() => setAddedPart(null)}
			/>
			<SearchFilterModalEcomm
				isOpen={showSearchFilterModal}
				onClose={() => setShowSearchFilterModal(false)}
			/>
		</PageContentWrapper>
	)
}

const contentContainer = (theme: Theme) => css`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 3rem;

	${theme.breakpoints.down("sm")} {
		grid-template-columns: auto;
		column-gap: 0;
	}
`

const sideBarFilter = (theme: Theme) => css`
	${theme.breakpoints.down("sm")} {
		display: none;
	}
`

const filterButton = (theme: Theme) => css`
	border-radius: 0.5rem;
	border: 1px solid #374151;
	padding: 0.7rem 1rem;
	font-size: 0.8rem;
	color: #374151;

	> span {
		font-weight: 500;
	}

	${theme.breakpoints.up("md")} {
		display: none;
	}
`

const gridPartCard = css`
	display: flex;
`

const partCardStyle = css`
	height: 100%;
`

const resultStyle = (theme: Theme) => css`
	margin-bottom: 0;

	${theme.breakpoints.down("md")} {
		margin-bottom: 2rem;
	}
`

const filterHeader = (theme: Theme) => css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 1rem;
`

const searchResultsStatusHeaderStyle = (theme: Theme) => css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;

	> div {
		align-self: normal;
	}

	${theme.breakpoints.down("sm")} {
		flex-direction: column;
		align-items: space-between;
	}
`

const snapSearchStyle = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
`

const nyrisDescriptionStyle = css`
	color: var(--Neutrals-700, #374151);
	font-family: "Atlas Grotesk";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`

const nyrisIconBoxStyle = css`
	display: flex;
	padding: 24px;
	justify-content: start;
	align-items: flex-start;
	gap: 10px;
	border-radius: 8px;
	border: 1px solid var(--Neutrals-300, #d1d5db);
	background: var(--Neutrals-100, #f3f4f6);
`

const snapSearchBoxStyle = css`
	margin-left: 12px;
	padding-right: 48px;
`
